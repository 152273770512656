import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import alpinePage from './components/page';

Alpine.plugin(intersect);

document.addEventListener('alpine:init', () => {
    Alpine.data('page', () => (alpinePage));
});

window.Alpine = Alpine;
Alpine.start();
